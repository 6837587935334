function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function addRow(prepend, hitsParentContainer) {
  var columnRow = document.createElement('div');
  columnRow.classList.add('row');

  if (prepend) {
    hitsParentContainer.prepend(columnRow);
  } else {
    hitsParentContainer.appendChild(columnRow);
  }

  return columnRow;
}

function addFilter(parent, extraClass, title) {
  var _filterWrapper$classL;

  var filterWrapper = document.createElement('div');

  if (title) {
    var header = document.createElement('h4');
    header.innerHTML = title;
    filterWrapper.appendChild(header);
  }

  if (extraClass) (_filterWrapper$classL = filterWrapper.classList).add.apply(_filterWrapper$classL, _toConsumableArray(extraClass));
  parent.appendChild(filterWrapper);
  return filterWrapper;
}

module.exports = {
  generateSearchContainer: function generateSearchContainer(algoliaSearchWrapper) {
    var searchContainer = document.createElement('div');
    searchContainer.classList.add('search_wrapper');
    algoliaSearchWrapper.appendChild(searchContainer);
    return searchContainer;
  },
  decorateSearchWrapper: function decorateSearchWrapper(algoliaSearchWrapper, config) {
    if (config.addClass) {
      var _algoliaSearchWrapper;

      (_algoliaSearchWrapper = algoliaSearchWrapper.classList).add.apply(_algoliaSearchWrapper, _toConsumableArray(config.addClass.split(' ')));
    }

    if (config.inline) {
      algoliaSearchWrapper.classList.add('inline');
    } else {
      algoliaSearchWrapper.classList.add('search_page');
    }
  },
  addRow: addRow,
  addFilter: addFilter,
  generateHitsRows: function generateHitsRows(_ref) {
    var config = _ref.config,
        hitsParentContainer = _ref.hitsParentContainer;
    var communityRow = addRow(false, hitsParentContainer);
    var communityHitsContainer = document.createElement('div'); // const communityFiltersShowMore = document.createElement('button')
    // communityFiltersShowMore.classList.add('show_more')

    communityHitsContainer.id = 'community_hits_container';
    communityHitsContainer.setAttribute('data-hit-container', '');
    communityHitsContainer.innerHTML = '<h2>Community</h2><div data-community-container></div>';
    communityRow.appendChild(communityHitsContainer);
    var docsRow = addRow(false, hitsParentContainer);
    var docsHitsContainer = document.createElement('div');
    docsHitsContainer.id = 'docs_hits_container';
    docsHitsContainer.setAttribute('data-hit-container', '');
    docsHitsContainer.innerHTML = '<h2>Documentation</h2><div data-docs-container></div>';
    docsRow.appendChild(docsHitsContainer);
    var trainingRow = addRow(false, hitsParentContainer);
    var trainingHitsContainer = document.createElement('div');
    trainingHitsContainer.id = 'training_hits_container';
    trainingHitsContainer.setAttribute('data-hit-container', '');
    trainingHitsContainer.innerHTML = '<h2>Training</h2><div data-training-container></div>';
    trainingRow.appendChild(trainingHitsContainer);

    if (config.firstResults == 'training') {
      hitsParentContainer.prepend(trainingRow);
    } else if (config.firstResults == 'docs') {
      hitsParentContainer.prepend(docsRow);
    }

    var moreButton = document.createElement('div');
    moreButton.innerHTML = 'More';
    moreButton.setAttribute('tabindex', '-1');
    moreButton.classList.add('more_hits_button');
    hitsParentContainer.appendChild(moreButton);
    return {
      communityRow: communityRow,
      communityHitsContainer: communityHitsContainer,
      docsRow: docsRow,
      docsHitsContainer: docsHitsContainer,
      trainingRow: trainingRow,
      trainingHitsContainer: trainingHitsContainer
    };
  }
};