export default function viewMore(_ref) {
  var section = _ref.section,
      searchTabsWrapper = _ref.searchTabsWrapper,
      hitsParentContainer = _ref.hitsParentContainer,
      communityHitsContainer = _ref.communityHitsContainer,
      docsHitsContainer = _ref.docsHitsContainer,
      trainingHitsContainer = _ref.trainingHitsContainer,
      communityRow = _ref.communityRow,
      docsRow = _ref.docsRow,
      trainingRow = _ref.trainingRow,
      communitySearchInstance = _ref.communitySearchInstance;

  if (searchTabsWrapper) {
    searchTabsWrapper.querySelectorAll('[data-section]').forEach(function (tab) {
      tab.classList.remove('active');
    });
    searchTabsWrapper.querySelector("[data-section=\"".concat(section, "\"]")).classList.add('active');
  }

  hitsParentContainer.querySelectorAll('[data-hit-container]').forEach(function (hitContainer) {
    hitContainer.parentNode.classList.add('hide');
    hitContainer.parentNode.classList.remove('show_all_filters');
  });
  if (communityHitsContainer.querySelector('[data-more]')) communityHitsContainer.querySelector('[data-more]').remove();
  if (docsHitsContainer.querySelector('[data-more]')) docsHitsContainer.querySelector('[data-more]').remove();
  if (trainingHitsContainer.querySelector('[data-more]')) trainingHitsContainer.querySelector('[data-more]').remove();
  communitySearchInstance.helper.state.hitsPerPage = 5;

  switch (section) {
    case 'community':
      communityRow.classList.remove('hide');
      communityRow.classList.add('show_all_filters');
      break;

    case 'docs':
      docsRow.classList.remove('hide');
      docsRow.classList.add('show_all_filters');
      break;

    case 'training':
      trainingRow.classList.remove('hide');
      trainingRow.classList.add('show_all_filters');
      break;

    case 'all':
      communitySearchInstance.communityIndex.getHelper().state.hitsPerPage = 5;
      communitySearchInstance.docsIndex.getHelper().state.hitsPerPage = 5;
      communitySearchInstance.trainingIndex.getHelper().state.hitsPerPage = 5;
      communitySearchInstance.helper.search();
      communityRow.classList.remove('hide');
      docsRow.classList.remove('hide');
      trainingRow.classList.remove('hide');
      communityRow.classList.remove('show_all_filters');
      docsRow.classList.remove('show_all_filters');
      trainingRow.classList.remove('show_all_filters');
      break;
  }

  if (section != 'all') {
    communitySearchInstance.communityIndex.getHelper().state.hitsPerPage = 20;
    communitySearchInstance.docsIndex.getHelper().state.hitsPerPage = 20;
    communitySearchInstance.trainingIndex.getHelper().state.hitsPerPage = 20;
    communitySearchInstance.helper.search();
  }
}