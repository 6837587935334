import { hits, index, configure, refinementList } from 'instantsearch.js/es/widgets';
import { addFilter } from './../html';
import get from 'lodash/get';
import forOwn from 'lodash/forOwn';
export default function (_ref) {
  var config = _ref.config,
      docsHitsContainer = _ref.docsHitsContainer,
      docsRow = _ref.docsRow,
      communitySearchInstance = _ref.communitySearchInstance;
  var docsFiltersConfig = [];

  if (get(config, 'filters.docs')) {
    forOwn(get(config, 'filters.docs'), function (value, key) {
      docsFiltersConfig.push("".concat(key, ":").concat(value));
    });
  }

  var docsIndex = index({
    indexName: config.docsIndexName || 'crawler_thoughtspot_documentation'
  });
  var target = '_blank';

  if (config.firstResults == 'docs' && !config.iframeEmbed) {
    target = '_self';
  }

  var docsWidgets = [configure({
    hitsPerPage: 5,
    filters: docsFiltersConfig.join(' AND ')
  }), hits({
    container: docsHitsContainer.querySelector('[data-docs-container]'),
    templates: {
      item: function item(hit) {
        var viewMore = '';
        var popoutButton = '';
        var hitsPerPage = get(communitySearchInstance, 'mainHelper.derivedHelpers[2].lastResults.hitsPerPage');

        if (!config.hideViewMore) {
          if (config.inline && hitsPerPage == 5 && hit.__position == hitsPerPage) {
            viewMore = '<a class="view_more" data-more="docs" href="#">View more</a>';
          }
        }

        if (config.showPopoutButton) {
          var popoutLink = 'https://docs.thoughtspot.com/';

          try {
            if (hit.__position == 1) {
              // const iframeLink = document.querySelector('[rel="canonical"]').href
              // const version = iframeLink.match(/.*?netlify\.app\/cloud\/([\w.\d]+)\//)[1]
              // popoutLink = `https://docs.thoughtspot.com/cloud/${version}/notes`
              popoutButton = "<a class=\"popout\" aria-label=\"Open docs site\" target=\"_blank\" href=\"".concat(popoutLink, "\"></a>");
            }
          } catch (e) {
            popoutButton = "<a class=\"popout\" aria-label=\"Open docs site\" target=\"_blank\" href=\"".concat(popoutLink, "\"></a>");
          }
        }

        return "".concat(popoutButton, "<a class=\"result\" href=\"").concat(hit.url, "\" target=\"").concat(target, "\">\n\t\t\t\t\t\t<h3><span class=\"tag first\">").concat(hit.version, "</span> ").concat(hit.title, "</h3>\n\t\t\t\t\t</a>").concat(viewMore);
      }
    }
  })];

  if (!config.inline) {
    var docsFilters = document.createElement('div');
    docsFilters.classList.add('refinement_filters');
    docsRow.appendChild(docsFilters);
    var versionFilterContainer = addFilter(docsFilters, ['docs_version'], 'Version');
    docsWidgets.push(refinementList({
      container: versionFilterContainer,
      attribute: 'version'
    }));
    docsWidgets.push(refinementList({
      container: addFilter(docsFilters, ['deployment'], 'Deployment'),
      attribute: 'deployment'
    }));
    var header = document.createElement('h3');
    header.style.marginBottom = '12px';
    header.innerHTML = 'Documentation';
    versionFilterContainer.append(header);
  }

  docsIndex.addWidgets(docsWidgets);
  return docsIndex;
}