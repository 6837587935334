import { hits, index, configure, refinementList } from 'instantsearch.js/es/widgets';
import { addFilter } from './../html';
import get from 'lodash/get';
export default function (_ref) {
  var config = _ref.config,
      trainingHitsContainer = _ref.trainingHitsContainer,
      trainingRow = _ref.trainingRow,
      communitySearchInstance = _ref.communitySearchInstance;
  var trainingIndex = index({
    indexName: config.trainingIndexName || 'thoughtspot_university'
  });
  var target = '_blank';

  if (config.firstResults == 'training' && !config.iframeEmbed) {
    target = '_self';
  }

  var trainingWidgets = [configure({
    hitsPerPage: 5
  }), hits({
    container: trainingHitsContainer.querySelector('[data-training-container]'),
    templates: {
      item: function item(hit) {
        var viewMore = '';
        var popoutButton = '';

        if (!config.hideViewMore) {
          var hitsPerPage = get(communitySearchInstance, 'mainHelper.derivedHelpers[3].lastResults.hitsPerPage');

          if (config.inline && hitsPerPage == 5 && hit.__position == hitsPerPage) {
            viewMore = '<a class="view_more" data-more="training" href="#">View more</a>';
          }
        }

        if (config.showPopoutButton && hit.__position == 1) {
          var popoutLink = 'https://training.thoughtspot.com/';
          popoutButton = "<a class=\"popout\" aria-label=\"Open training site\" target=\"_blank\" href=\"".concat(popoutLink, "\"></a>");
        }

        return "".concat(popoutButton, "<a class=\"result\" href=\"").concat(hit.url, "\" target=\"").concat(target, "\">\n\t\t\t\t\t\t<h3><span class=\"tag first\">").concat(hit.type, "</span> ").concat(hit.lesson_title ? hit.lesson_title + ' | ' : '').concat(hit.course_title, "</h3>\n\t\t\t\t\t</a>").concat(viewMore);
      }
    }
  })];

  if (!config.inline) {
    var trainingFilters = document.createElement('div');
    trainingFilters.classList.add('refinement_filters');
    trainingRow.appendChild(trainingFilters);
    var typeFilterContainer = addFilter(trainingFilters, ['training_type'], 'Type');
    trainingWidgets.push(refinementList({
      container: typeFilterContainer,
      attribute: 'type'
    }));
    trainingWidgets.push(refinementList({
      container: addFilter(trainingFilters, ['delivery_type'], 'Delivery'),
      attribute: 'facets.Delivery'
    }));
    trainingWidgets.push(refinementList({
      container: addFilter(trainingFilters, ['deployment_type'], 'Deployment'),
      attribute: 'facets.Deployment'
    }));
    var header = document.createElement('h3');
    header.style.marginBottom = '12px';
    header.innerHTML = 'Training';
    typeFilterContainer.append(header);
  }

  trainingIndex.addWidgets(trainingWidgets);
  return trainingIndex;
}