import striptags from 'striptags';
import get from 'lodash/get';
import truncate from 'truncate';
var baseUrl = 'https://community.thoughtspot.com/customers/s/';

function decodeHtmlEntities(html) {
  // Create a temporary textarea element
  var textarea = document.createElement('textarea'); // Set the provided HTML encoded string as its content

  textarea.innerHTML = html; // The browser automatically decodes the content of the textarea, so return it

  return textarea.value;
}

export function communityHitTemplates(_ref) {
  var config = _ref.config,
      communitySearchInstance = _ref.communitySearchInstance;
  var target = '_blank';

  if (config.firstResults == 'community' && !config.iframeEmbed) {
    target = '_self';
  }

  return {
    item: function item(hit) {
      var template = '';
      var popoutButton = '';

      if (hit.object_type == 'FeedItemTextPost') {
        var matchedText = [];

        if (hit.parent_type == 'CollaborationGroup') {
          var content = decodeHtmlEntities(hit._highlightResult.body.value);
          var regex = /(<mark>.*?<\/mark>)/gm;
          var surroundingChars = 30; // Split matchedString into an array of strings, each string is either a match or not a match

          var matches = content.match(regex);

          if (matches) {
            matches.forEach(function (matchedStringPart, index) {
              var matchIndex = content.indexOf(matchedStringPart);
              var matchStartIndex = matchIndex;
              var matchEndIndex = matchIndex + matchedStringPart.length;
              var preMatchLength = matchIndex - surroundingChars >= 0 ? surroundingChars : matchIndex;
              var postMatchLength = matchIndex + matchedStringPart.length + surroundingChars <= content.length ? surroundingChars : content.length - matchEndIndex;
              var preMatchString = striptags(content.slice(0, matchStartIndex));
              var postMatchString = striptags(content.slice(matchEndIndex));
              preMatchString = preMatchString.slice(-preMatchLength);
              postMatchString = postMatchString.slice(0, postMatchLength);
              matchedText.push(preMatchString + matchedStringPart + postMatchString);
            });
          }
        }

        var communityUrl = 'https://community.thoughtspot.com/customers/s/feed/';
        template = "<a class=\"result\" href=\"".concat(communityUrl).concat(hit.id, "\" target=\"").concat(target, "\">\n\t\t\t\t\t\t\t\t\t<h3><span class=\"tag first\">Post</span>").concat(hit.title || hit.parent_name, "</h3>\n\t\t\t\t\t\t\t\t\t").concat(matchedText.length ? "<p class=\"matches\">... ".concat(matchedText.slice(0, 5).join(' ... '), " ...</p>") : '', "\n\t\t\t\t\t\t\t\t</a>");
      } else if (hit.object_type == 'FeedItemQuestionPost') {
        var _communityUrl = 'https://community.thoughtspot.com/customers/s/question/';
        template = "<a class=\"result\" href=\"".concat(_communityUrl).concat(hit.id, "\" target=\"").concat(target, "\">\n\t\t\t\t\t\t\t\t<h3><span class=\"tag first\">Discussions</span>").concat(hit.title || hit.body, "</h3>\n\t\t\t\t\t\t\t</a>");
      } else if (hit.object_type == 'FeedItemLead') {
        var _communityUrl2 = "".concat(baseUrl, "lead/");

        template = "<a class=\"result\" href=\"".concat(_communityUrl2).concat(hit.parent_id, "\" target=\"").concat(target, "\">\n\t\t\t\t\t\t\t\t<h3><span class=\"tag first\">lead: ").concat(hit.parent_name, "</span> ").concat(hit.body, "</h3>\n\t\t\t\t\t\t\t</a>");
      } else if (hit.object_type == 'Lead') {
        var _communityUrl3 = "".concat(baseUrl, "lead/");

        template = "<a class=\"result lead\" href=\"".concat(_communityUrl3).concat(hit.id, "\" target=\"").concat(target, "\">\n\t\t\t\t\t\t\t\t<img src=\"https://community.thoughtspot.com/customers").concat(hit.photo_url, "\" />\n\t\t\t\t\t\t\t\t<h3>").concat(hit.name, "</h3>\n\t\t\t\t\t\t\t\t<p class=\"last_activity_date\"><span>last activity:</span> ").concat(hit.last_activity_date, "</p>\n\t\t\t\t\t\t\t\t<p class=\"email\"><span>email:</span> ").concat(hit.email, "</p>\n\t\t\t\t\t\t\t\t<p><span>phone:</span> ").concat(hit.phone, "</span></p>\n\t\t\t\t\t\t\t\t<p><span>industry:</span> ").concat(hit.industry, "</span></p>\n\t\t\t\t\t\t\t\t<p><span>location:</span> ").concat(hit.country).concat(hit.state ? ' , ' + hit.state : '', "</span></p>\n\t\t\t\t\t\t\t</a>");
      } else if (hit.object_type == 'KnowledgeArticle') {
        var _communityUrl4 = "".concat(baseUrl, "article/");

        template = "<a class=\"result\" href=\"".concat(_communityUrl4).concat(hit.url_name, "\" target=\"").concat(target, "\">\n\t\t\t\t\t\t\t\t<h3><span class=\"tag first\">Article</span> ").concat(hit.title, "</h3>\n\t\t\t\t\t\t\t</a>");
      } else if (hit.object_type == 'Group') {
        var _communityUrl5 = "".concat(baseUrl, "group/");

        template = "<a class=\"result\" href=\"".concat(_communityUrl5).concat(hit.id, "\" target=\"").concat(target, "\">\n\t\t\t\t\t\t\t\t<h3><span class=\"tag first\">Group</span>").concat(hit.name, "</h3>\n\t\t\t\t\t\t\t</a>");
      } else if (hit.object_type == 'Case') {
        var _communityUrl6 = "".concat(baseUrl, "case/");

        template = "<a class=\"result\" href=\"".concat(_communityUrl6).concat(hit.id, "\" target=\"").concat(target, "\">\n\t\t\t\t\t\t\t\t<h3><span class=\"tag first\">Case</span> ").concat(hit.subject, "</h3>\n\t\t\t\t\t\t\t</a>");
      } else if (hit.object_type == 'CaseComment') {
        var _communityUrl7 = "".concat(baseUrl, "case/");

        template = "<a class=\"result\" href=\"".concat(_communityUrl7).concat(hit.parent_id, "\" target=\"").concat(target, "\">\n\t\t\t\t\t\t\t\t<h3><span class=\"tag first\">Case Comment</span> ").concat(hit.parent_subject, "</h3>\n\t\t\t\t\t\t\t</a>");
      } else if (hit.object_type == 'File') {
        var _communityUrl8 = "".concat(baseUrl, "contentdocument/");

        var preview = '';

        if (['PNG', 'SVG', 'JPG', 'JPEG', 'GIF'].indexOf(hit.file_type) > -1) {
          preview = "<img src=\"https://community.thoughtspot.com/customers/sfc/servlet.shepherd/version/renditionDownload?rendition=ORIGINAL_Png&versionId=".concat(hit.latest_published_version_id, "&operationContext=CHATTER\" />");
        }

        template = "<a class=\"result file\" href=\"".concat(_communityUrl8).concat(hit.id, "\" target=\"").concat(target, "\">\n\t\t\t\t\t\t\t\t<h3><span class=\"tag first\">").concat(hit.file_type, "</span> ").concat(hit.title, "</h3>\n\t\t\t\t\t\t\t\t").concat(preview, "\n\t\t\t\t\t\t\t</a>");
      } else if (hit.object_type == 'IdeaComment') {
        var _communityUrl9 = "https://community.thoughtspot.com/s/idea/".concat(hit.idea_id, "/detail");

        template = "<a class=\"result\" href=\"".concat(_communityUrl9, "\" target=\"").concat(target, "\">\n\t\t\t\t\t\t\t\t<h3><span class=\"tag first\">Idea Comment</span> ").concat(truncate(striptags(hit.comment_body), 240), "</h3>\n\t\t\t\t\t\t\t</a>");
      } else if (hit.object_type == 'Idea') {
        var _communityUrl10 = "https://community.thoughtspot.com/s/idea/".concat(hit.id, "/detail");

        template = "<a class=\"result\" href=\"".concat(_communityUrl10, "\" target=\"").concat(target, "\">\n\t\t\t\t\t\t\t\t<h3><span class=\"tag first\">Idea</span> ").concat(hit.title, "</h3>\n\t\t\t\t\t\t\t</a>");
      }

      var hitsPerPage = get(communitySearchInstance, 'mainHelper.derivedHelpers[1].lastResults.hitsPerPage');

      if (!config.hideViewMore) {
        template = config.inline && hitsPerPage == 5 && hit.__position == hitsPerPage ? template + '<a class="view_more" data-more="community" href="#">View more</a>' : template;
      }

      if (config.showPopoutButton && hit.__position == 1) {
        var popoutLink = 'https://community.thoughtspot.com/';
        popoutButton = "<a class=\"popout\" aria-label=\"Open community site\" target=\"_blank\" href=\"".concat(popoutLink, "\"></a>");
      }

      return popoutButton + template;
    }
  };
}