import get from 'lodash/get';
export default function getProfileData(config) {
  var caseField = null;
  var userId = get(config, 'user.id');
  var profileName = get(config, 'user.profileName');

  if (profileName == 'ThoughtSpot Customer Community User: Manager') {
    caseField = 'account_id';
    userId = get(config, 'user.accountId');
  } else if (profileName == 'ThoughtSpot Customer Community User: Individual Contributor') {
    caseField = 'contact_id';
    userId = get(config, 'user.contactId');
  }

  return {
    caseField: caseField,
    profileName: profileName,
    userId: userId
  };
}