function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import instantsearch from 'instantsearch.js';
import algoliasearch from 'algoliasearch/lite';
import { searchBox, hits, index, configure, refinementList, toggleRefinement } from 'instantsearch.js/es/widgets';
import { communityHitTemplates } from './rendering';
import flatpickr from "flatpickr";
import 'flatpickr/dist/themes/light.css';
import { addRow, addFilter } from './../html';
import { connectRange } from 'instantsearch.js/es/connectors';
import { fromUnixTime, format } from 'date-fns';
export default function (_ref) {
  var config = _ref.config,
      secureApiKey = _ref.secureApiKey,
      searchContainer = _ref.searchContainer,
      communityRow = _ref.communityRow,
      communityHitsContainer = _ref.communityHitsContainer,
      hitsParentContainer = _ref.hitsParentContainer;
  var communityFilters = null;
  var communityMoreFilters = null;
  var searchTabsWrapper = null;
  var communitySearchInstance = instantsearch({
    indexName: config.communityIndexName || 'Community',
    searchClient: algoliasearch('FFSASUS8DI', secureApiKey),
    searchFunction: function searchFunction(helper) {
      if (helper.state.query || forceSearch) {
        helper.search();
      }
    }
  });
  var communityWidgets = [configure({
    hitsPerPage: 5
  }), hits({
    container: communityHitsContainer.querySelector('[data-community-container]'),
    templates: communityHitTemplates({
      config: config,
      communitySearchInstance: communitySearchInstance
    })
  })];

  if (!config.inline) {
    communityFilters = document.createElement('div');
    communityMoreFilters = document.createElement('div');
    communityMoreFilters.classList.add('more_filters');
    communityMoreFilters.classList.add('expand'); // Start expanded

    communityFilters.classList.add('refinement_filters'); // communityFiltersShowMore.innerHTML = 'Show filters'

    communityRow.appendChild(communityFilters);
    var tabsRow = addRow(true, hitsParentContainer);
    searchTabsWrapper = document.createElement('div');
    searchTabsWrapper.classList.add('filter_tabs');
    tabsRow.appendChild(searchTabsWrapper);
    searchTabsWrapper.innerHTML = "<a href=\"#all\" data-section=\"all\">All</a>\n\t\t\t<a href=\"#docs\" data-section=\"docs\">Documentation</a>\n\t\t\t<a href=\"#community\" data-section=\"community\">Community</a>\n\t\t\t<a href=\"#training\" data-section=\"training\">Training</a>";
    var topicsContainer = addFilter(communityFilters, ['topics_filter'], 'Topics');
    communityWidgets.push(refinementList({
      container: topicsContainer,
      attribute: 'topics_for_refinement',
      limit: 5,
      showMoreLimit: 10,
      showMore: true,
      templates: {
        showMoreText: function showMoreText(props) {
          return props.isShowingMore ? 'Show less' : 'Show more';
        }
      }
    }));
    var objectTypeFilter = addFilter(communityFilters, ['additional_filter', 'object_type_filter', 'expand'], 'Content type');
    communityWidgets.push(refinementList({
      container: objectTypeFilter,
      attribute: 'object_type',
      limit: 10,
      showMore: false,
      transformItems: function transformItems(items) {
        return items.map(function (item) {
          var label = item.label;

          switch (item.label) {
            case 'FeedItemQuestionPost':
              label = 'Discussions';
              break;

            case 'FeedItemTextPost':
              label = 'Comment';
              break;

            case 'CaseComment':
              label = 'Case Comment';
              break;

            case 'KnowledgeArticle':
              label = 'Article';
              break;
          }

          return _objectSpread(_objectSpread({}, item), {}, {
            highlighted: label
          });
        });
      }
    }));
    var bestCommentFilter = addFilter(communityFilters, ['additional_filter', 'best_comment_filter', 'expand'], 'Show content');
    communityWidgets.push(toggleRefinement({
      container: bestCommentFilter,
      attribute: 'has_best_comment',
      on: true,
      templates: {
        labelText: 'With Best Answer'
      }
    }));
    var hasCommentsFilter = addFilter(communityFilters, ['additional_filter', 'has_comments_filter', 'expand']);
    communityWidgets.push(toggleRefinement({
      container: hasCommentsFilter,
      attribute: 'has_comments',
      templates: {
        labelText: 'With Comments'
      }
    }));
    var hasNoCommentsFilter = addFilter(communityFilters, ['additional_filter', 'has_no_comments_filter', 'expand']);
    communityWidgets.push(toggleRefinement({
      container: hasNoCommentsFilter,
      attribute: 'has_no_comments',
      templates: {
        labelText: 'Without Comments'
      }
    }));
    var formattedStartDate = '';
    var formattedEndDate = '';

    var renderDateRangeInput = function renderDateRangeInput(renderOptions, isFirstRender) {
      var start = renderOptions.start,
          range = renderOptions.range,
          refine = renderOptions.refine,
          widgetParams = renderOptions.widgetParams;

      var _start = _slicedToArray(start, 2),
          min = _start[0],
          max = _start[1];

      if (isFirstRender) {
        var _form = document.createElement('form');

        _form.classList.add('community_datepicker');

        widgetParams.container.appendChild(_form);
        refine([min, max]); // Set initial value to include everything.

        return;
      }

      var form = widgetParams.container.querySelector('form');
      form.innerHTML = "\n\t\t\t\t<input class=\"datepicker_input\" type=\"text\" />\n\t\t\t\t<input data-min\n\t\t\t\t\ttabindex=\"0\"\n\t\t\t\t\ttype=\"text\"\n\t\t\t\t\tname=\"min\"\n\t\t\t\t\tvalue=\"".concat(formattedStartDate, "\"\n\t\t\t\t\tplaceholder=\"Min date\"\n\t\t\t\t/>\n\t\t\t\t<span>to</span>\n\t\t\t\t<input data-max\n\t\t\t\t\ttabindex=\"0\"\n\t\t\t\t\ttype=\"text\"\n\t\t\t\t\tname=\"max\"\n\t\t\t\t\tvalue=\"").concat(formattedEndDate, "\"\n\t\t\t\t\tplaceholder=\"Max date\"\n\t\t\t\t/>\n\t\t\t\t<input type=\"submit\" hidden />\n\t\t\t");
      var startDate = form.querySelector('[data-min]');
      var endDate = form.querySelector('[data-max]');
      form.flatpickr = flatpickr(form.querySelector('.datepicker_input'), {
        mode: "multiple",
        dateFormat: "U",
        onChange: function onChange(selectedDates, dateStr, instance) {
          var dateTimestamps = dateStr.split(',');
          dateTimestamps = dateTimestamps.map(function (timestamp) {
            return timestamp.trim();
          });

          if (dateTimestamps[0]) {
            formattedStartDate = format(fromUnixTime(dateTimestamps[0]), 'LLL do, yyyy');
            startDate.value = formattedStartDate;
          }

          if (dateTimestamps[1]) {
            formattedEndDate = format(fromUnixTime(dateTimestamps[1]), 'LLL do, yyyy');
            endDate.value = formattedEndDate;
          }

          if (dateTimestamps.length == 2) {
            instance.close();
            instance.clear();
            refine(dateTimestamps.map(function (timestamp) {
              return timestamp * 1000;
            }));
          }
        }
      });
    }; // Create the custom widget


    var customRangeInput = connectRange(renderDateRangeInput);
    var dateFilter = addFilter(communityFilters, ['additional_filter', 'date_filter', 'expand'], 'Date');
    communityWidgets.push(customRangeInput({
      container: dateFilter,
      attribute: 'merged_date',
      min: 0,
      max: 999999999999999
    }));
    var additionalFilterGroup = document.createElement('div');
    additionalFilterGroup.classList.add('additional_filter_group');
    communityMoreFilters.appendChild(objectTypeFilter);
    additionalFilterGroup.appendChild(bestCommentFilter);
    additionalFilterGroup.appendChild(hasCommentsFilter);
    additionalFilterGroup.appendChild(hasNoCommentsFilter);
    communityMoreFilters.appendChild(additionalFilterGroup);
    communityMoreFilters.appendChild(dateFilter);
    var header = document.createElement('h3');
    header.innerHTML = 'Community';
    topicsContainer.append(header);
    communityFilters.appendChild(communityMoreFilters);
  } // We add an Index for the community index despite it being the main index for our instantsearch object so we can control its settings independent of the other indexes.


  var communityIndex = index({
    indexName: 'Community'
  }).addWidgets(communityWidgets);
  communityWidgets = [searchBox({
    container: searchContainer
  }), communityIndex];
  communitySearchInstance.communityIndex = communityIndex;
  return {
    communitySearchInstance: communitySearchInstance,
    communityWidgets: communityWidgets,
    communityFilters: communityFilters,
    communityMoreFilters: communityMoreFilters,
    searchTabsWrapper: searchTabsWrapper
  };
}