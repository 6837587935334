import viewMoreFilter from '../view_more';

function setExplicitHeights(_ref) {
  var communityFilters = _ref.communityFilters,
      height = _ref.height;
  communityFilters.querySelectorAll('.additional_filter').forEach(function (filter) {
    if (height) {
      filter.style.height = height;
    } else {
      var calculatedHeight = filter.childNodes[0].offsetHeight + (filter.childNodes[1] ? filter.childNodes[1].offsetHeight + 15 : 0) + 'px';
      filter.style.height = calculatedHeight;
    }
  });
}

function getResultsHeight(_ref2) {
  var hitsParentContainer = _ref2.hitsParentContainer;
  var totalHeight = 0;
  hitsParentContainer.querySelectorAll('.row').forEach(function (row) {
    totalHeight += row.offsetHeight;
  });
  return totalHeight ? totalHeight + 10 + 'px' : '0px';
}

export function attachEvents(_ref3) {
  var config = _ref3.config,
      algoliaSearchWrapper = _ref3.algoliaSearchWrapper,
      searchTabsWrapper = _ref3.searchTabsWrapper,
      hitsParentContainer = _ref3.hitsParentContainer,
      communityHitsContainer = _ref3.communityHitsContainer,
      docsHitsContainer = _ref3.docsHitsContainer,
      trainingHitsContainer = _ref3.trainingHitsContainer,
      communityRow = _ref3.communityRow,
      docsRow = _ref3.docsRow,
      trainingRow = _ref3.trainingRow,
      communitySearchInstance = _ref3.communitySearchInstance,
      searchInput = _ref3.searchInput,
      clearInputButton = _ref3.clearInputButton,
      clearSearch = _ref3.clearSearch;
  clearInputButton.addEventListener('mousedown', function (e) {
    clearSearch(e, config.inline ? true : false);
    e.preventDefault();
    e.stopPropagation();
  });
  clearInputButton.addEventListener('click', function (e) {
    clearSearch(e, config.inline ? true : false);
    e.preventDefault();
    e.stopPropagation();
  });
  clearInputButton.addEventListener('touchstart', function (e) {
    clearSearch(e, config.inline ? true : false);
    e.preventDefault();
    e.stopPropagation();
  });

  if (!config.inline) {
    searchTabsWrapper.addEventListener('click', function (e) {
      var section = e.target.dataset.section;
      viewMoreFilter({
        section: section,
        searchTabsWrapper: searchTabsWrapper,
        hitsParentContainer: hitsParentContainer,
        communityHitsContainer: communityHitsContainer,
        docsHitsContainer: docsHitsContainer,
        trainingHitsContainer: trainingHitsContainer,
        communityRow: communityRow,
        docsRow: docsRow,
        trainingRow: trainingRow,
        communitySearchInstance: communitySearchInstance
      });
      return false;
    });
  }

  if (config.inline) {
    var viewMoreClicked = function viewMoreClicked(e) {
      var target = e.target;

      if (target.dataset.more) {
        if (config.viewMoreFunction) {
          config.viewMoreFunction(target.dataset.more, searchInput.value, this);
        } else {
          viewMoreFilter({
            section: target.dataset.more,
            searchTabsWrapper: searchTabsWrapper,
            hitsParentContainer: hitsParentContainer,
            communityHitsContainer: communityHitsContainer,
            docsHitsContainer: docsHitsContainer,
            trainingHitsContainer: trainingHitsContainer,
            communityRow: communityRow,
            docsRow: docsRow,
            trainingRow: trainingRow,
            communitySearchInstance: communitySearchInstance
          });
          target.remove();
        }

        e.preventDefault();
        e.stopPropagation();
      }
    };

    var moreButton = hitsParentContainer.querySelector('.more_hits_button');

    if (moreButton) {
      moreButton.addEventListener('click', function (e) {
        e.preventDefault();
        hitsParentContainer.scroll({
          top: hitsParentContainer.scrollHeight,
          behavior: "smooth"
        });
      });
    }

    hitsParentContainer.addEventListener('click', viewMoreClicked);
  }

  if (!config.inline && config.onCloseButton) {
    var closeButton = document.createElement('button');
    closeButton.classList.add('close');
    algoliaSearchWrapper.appendChild(closeButton);
    closeButton.addEventListener('click', function () {
      config.onCloseButton();
    });
    document.body.addEventListener('keydown', function (e) {
      if (e.key == 'Escape') {
        if (!searchInput.value || e.target != searchInput) {
          config.onCloseButton();
        }
      }
    });
  }
}
export function attachCommunityEvents(_ref4) {
  var communitySearchInstance = _ref4.communitySearchInstance,
      hitsParentContainer = _ref4.hitsParentContainer,
      config = _ref4.config,
      communityFilters = _ref4.communityFilters,
      clearInputButton = _ref4.clearInputButton,
      searchInput = _ref4.searchInput;
  communitySearchInstance.on('render', function () {
    function onScroll(e) {
      if (hitsParentContainer.scrollHeight - 40 < hitsParentContainer.clientHeight + hitsParentContainer.scrollTop) {
        hitsParentContainer.classList.remove('more');
      } else {
        hitsParentContainer.classList.add('more');
      }
    }

    if (hitsParentContainer.classList.contains('expanded')) {
      setTimeout(function () {
        hitsParentContainer.style.height = getResultsHeight({
          hitsParentContainer: hitsParentContainer
        });

        if (parseInt(hitsParentContainer.style.height) > parseInt(getComputedStyle(hitsParentContainer).maxHeight)) {
          hitsParentContainer.classList.add('more');
          hitsParentContainer.addEventListener('scroll', onScroll);
        } else {
          hitsParentContainer.classList.remove('more');
          hitsParentContainer.removeEventListener('scroll', onScroll);
        }
      }, 100);
    }

    if (config.inline == false && communityFilters && communityFilters.querySelector('.more_filters.expand')) {
      setExplicitHeights({
        communityFilters: communityFilters
      });
    }
  });

  function onSearchInput(e) {
    // We can probably squeeze out some more efficiency by disabling force-search after the initial focus
    if (e.type == 'focus') {
      window.forceSearch = true;
      communitySearchInstance.helper.search();
    }

    if (!window.focusedBefore || searchInput.value) {
      // clearInputButton.setAttribute('tabindex', '')
      searchInput.parentNode.classList.add('expanded');
      searchInput.classList.add('focused');
      hitsParentContainer.classList.add('expanded');
      document.body.classList.add('algolia_hits_expanded');
      hitsParentContainer.style.height = getResultsHeight({
        hitsParentContainer: hitsParentContainer
      });
      hitsParentContainer.querySelectorAll('a').forEach(function (a) {
        a.setAttribute('tabindex', '');
      });
    }

    setTimeout(function () {
      hitsParentContainer.classList.remove('no_transition');
      hitsParentContainer.classList.remove('hide_initially');
    }, 300);
    window.focusedBefore = true;
  }

  searchInput.addEventListener('input', onSearchInput);
  searchInput.addEventListener('focus', onSearchInput);
}